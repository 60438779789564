import './App.css';
import React, { useState, useEffect } from 'react';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

// Configure Laravel Echo
window.Pusher = Pusher;



window.Echo = new Echo({
  broadcaster: 'reverb',
  key: '1gmbommcb7fwyxlxjdjv',
  wsHost: 'app.khelplayer.com',  // Correct WebSocket Host
  wsPort: 8080,                  // WebSocket Port
  encrypted: true,               // Ensure encryption
  forceTLS: true,                // Use HTTPS
  enabledTransports: ['ws', 'wss'],     // Enable WebSocket transport
});



const generateUniqueId = () => {
  return Date.now().toString() + Math.random().toString(36).substr(2, 9);
};



function App() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const channel = window.Echo.channel('users');
    channel.listen('UserCreated', ({ user }) => {
      setUsers((prevUsers) => [...prevUsers, user]);
    });

    return () => {
      channel.stopListening('UserCreated');
      window.Echo.leaveChannel('users');
    };
  }, []);


  return (
    <div className="App">
      <h1>Welcome to React with Laravel Reverb!</h1>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, i) =>
          (
            <tr key={`${i}_${user.id || user.email}`}>
              <td>{user.id}</td>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{user.created_at}</td>
            </tr>
          )
          )}
        </tbody>
      </table>
    </div>
  );
}

export default App;
